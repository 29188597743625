<template>
    <div>
        <div class="form-container">
            <!-- <v-form ref="form" lazy-validation > -->
            <v-form ref="form">
                <v-row >
                    <v-col cols=12>
                        <p class="form-title">Precio de compra de la vivienda </p>
                        <p class="form-subtitle">Si no lo conoces exactamente, pon un importe aproximado</p>
                        <v-currency-field
                            v-model="prescoringData.precio_compra"
                            outlined
                            append-icon="mdi-currency-eur"
                            dense
                            :rules="[...ruleRequired]"
                            v-on:keyup="setFilled"
                        ></v-currency-field>
                    </v-col>

                    <v-col cols=12>
                        <p class="form-title">Comunidad autónoma</p>
                        <p class="form-subtitle"> Este dato nos servirá para calcular el importe del ITP (Impuesto de Transmisiones Patrimoniales)</p>
                        <v-autocomplete 
                            outlined
                            v-model="prescoringData.ccaa"
                            :items="vars.ccaa"
                            item-text="name"
                            item-value="code"
                            dense
                            :rules="[...ruleRequired]"
                            v-on:keyup="setFilled"
                        />
                    </v-col>

                    <v-col cols=12>
                        <p class="form-title">Tipo de vivienda</p>
                        <p class="form-subtitle">Necesitamos este dato para calcular los impuestos</p>
                        <div class="options-container">
                            <div v-for="item in vars.new_build" :key="item.code" 
                                class="radio-button_container" 
                                @click="prescoringData.vivienda_nueva=item.code"
                            >
                            <div class="circle">  
                                <div v-if="prescoringData.vivienda_nueva==item.code" class="isChecked"></div>
                            </div>
                            <label>{{item.name}}</label>
                            </div>
                        </div>
                    </v-col>

                    <v-col cols=12>
                        <p class="form-title">Estado del proceso de compra</p>
                        <p class="form-subtitle"> Queremos ayudarte y conocer en qué momento del proceso te encuentras es relevante</p>
                        <div class="options-container">
                            <div v-for="item in vars.tipo_encontrada" :key="item.funnel100" 
                                class="radio-button_container" 
                                @click="prescoringData.estado_proceso=item.code"
                            >
                            <div class="circle">  
                                <div v-if="prescoringData.estado_proceso==item.code" class="isChecked"></div>
                            </div>
                            <label>{{item.funnel100}}</label>
                            </div>
                        </div>
                    </v-col>

                    <v-col cols=12 v-if="prescoringData.precio_compra > 0">
                        <p class="form-title">Para comprar una vivienda de <span style="color:#68CAD4">{{ prescoringData.precio_compra | currency }}€</span> 
                                necesitas tener ahorrado o conseguir de la familia <span style="color:#68CAD4">{{ tenPercent(prescoringData.precio_compra) | currency }}€</span> 
                                ¿es posible?</p>
                        <p class="form-subtitle"> Aquí lo importante es saber si tienes, al menos, para los gastos de la operación: si es así tenemos un producto exclusivo. 
                            En caso de que no los tengas, podemos ayudarte pero la financiación es más difícil de conseguir...</p>
                        <div class="options-container">
                            <div v-for="item in vars.yes_no_field" :key="item.name" 
                                class="radio-button_container" 
                                @click="prescoringData.producto_hipotecario=item.code"
                            >
                            <div class="circle">  
                                <div v-if="prescoringData.producto_hipotecario==item.code" class="isChecked"></div>
                            </div>
                            <label>{{item.name}}</label>
                            </div>
                        </div>
                    </v-col>

                </v-row>
            </v-form>
        </div>
        <div class="actions_container button-unique">
            <div clas="d-flex flex-column justify-end">
                <span v-if="isFilledOperation==false" class="filled-message ml-4"> Faltan datos obligatorios</span>
                <button class="button-primary_element mt-4" @click="goToNext">
                    <span class="button-primary_text">Continuar</span>
                </button>
            </div>
        </div>  
    </div>  
</template>

<script>
    import { mapState, mapActions, mapMutations } from 'vuex'
    import rulesMixin from '../../mixins/rulesMixin';


    export default {
        name: 'InitialView',
        mixins: [rulesMixin],
        data() {
            return {
            }
        },
        mounted() {
            window.scrollTo(0, 0);
        },
        computed: {
            ...mapState([
                'vars',
                'isFilledOperation',
                'prescoringData',
                'activeTab'
            ]),            
        },
        methods: {
            ...mapActions([
                'setActiveTab'
            ]),
            ...mapMutations([
                'SET_IS_FILLED',
                'SET_PRESCORING_DATA',
                'SET_ACTIVE_TAB'
            ]),
        setFilled(){
            this.SET_IS_FILLED(true)
        },
        tenPercent(value) {
            return value * 0.10;
        },
        goToNext(){
            if(this.$refs.form.validate()){
                let params = {};
                params['precio_compra'] = this.prescoringData.precio_compra;
                params['ccaa'] = this.prescoringData.ccaa;
                params['vivienda_nueva'] = this.prescoringData.vivienda_nueva;
                params['estado_proceso'] = this.prescoringData.estado_proceso;
                params['producto_hipotecario'] =  this.prescoringData.producto_hipotecario == 1 ? 'myinvestor' : 'helloteca'
                console.log("params INTIAL", params)  
                this.SET_PRESCORING_DATA(params); 

                if(this.prescoringData.producto_hipotecario == 'myinvestor'){
                    //si el cliente contesta SI, tengo ahorros > producto MY  =  80/20
                    this.SET_ACTIVE_TAB('MotivationView');
                } else {
                    //si el cliente contesta NO, no tengo ahorros, preguntamos por aval > producto HELLO  =  DGH
                    this.SET_ACTIVE_TAB('AvalView');
                }
            }else {
                return this.SET_IS_FILLED(false);
            }
        },
        }
    }
</script>

<style>
    .button-unique{
        justify-content: end !important;
    }
</style>

