<template>
    <div style="width:100%; display:flex; padding: 10px;">
        <img src="../assets/logo_color.png" width="120px" />
    </div>
</template>

<script>
    export default {
        name: 'HelloHeader',
        data: () => {
            return {
            };
        },
        methods:{
        },
    };
</script>

<style>
</style>