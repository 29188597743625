<template>
    <div>
        <div class="title-container">
            <p class="title-box">TU HIPOTECA 100% PARECE <span style="color:rgb(102, 205, 102)">VIABLE</span></p> 
        </div>
        <!-- BLOQUE CASITAS -->
            <!-- HELLO -->
            <div v-if="scoringData.producto_hipotecario === 'helloteca'"  class="casitas-container">
                <div class="casitas-img_hello">
                    <img src="../../assets/casa.png" class="casita-img_HELLO"/>
                    <img src="../../assets/casa.png" class="casita-img_2"/>
                </div>
                <div style="width: 70%;display:flex; flex-direction: column;  align-items: flex-end;  padding-left: 4% ">
                    <h3 class="casita-text" >Hipoteca 80% {{scoringData.resultado.hipoteca_80.valor | currency}}€</h3>
                    <p>Hipoteca sobre la vivienda que compras</p>
                    <span style="margin-right: 100px; color: #12868D; font-size: 50px;">+</span>
                    <h3 class="casita-text"  style="margin-top:8%">Hipoteca 20% +Gastos: {{scoringData.resultado.hipoteca_20.valor | currency}}€</h3>
                    <p>Hipoteca sobre la vivienda que avala</p>
                </div>
            </div>   

            <!-- MY -->
            <div v-if="scoringData.producto_hipotecario === 'myinvestor'" class="casitas-container" >
                <div class="casitas-img_my">
                    <img src="../../assets/casa.png" class="casita-img_MY"/>
                </div>
                <div style="width: 65%;display:flex; flex-direction: column;  align-items: flex-end; padding-left: 4% ">
                    <h3 class="casita-text">Hipoteca 80% {{scoringData.resultado.hipoteca_80.valor | currency}}€</h3>
                    <p>FIJA: {{scoringData.resultado.hipoteca_80.interes}}% sin vinculaciones</p>
                    <h3 class="casita-text">Hipoteca 20% {{scoringData.resultado.hipoteca_20.valor  | currency}}€</h3>
                    <p>FIJA: {{scoringData.resultado.hipoteca_20.interes}}% sin vinculaciones</p>
                </div>
            </div>    
            <p v-if="scoringData.producto_hipotecario === 'myinvestor'" style="font-size: small;">Es una única hipoteca que recae sobre la vivienda que compras, solo que se divide en dos tramos de precio.</p>

            <!-- BLOQUE DATOS -->
            <div class="datos-container">
                <p>Precio de la Vivienda: <span class="datos-container_numeros"> {{scoringData.resultado.precio_compra | currency}}€ </span></p>
                <p>Total Gastos de la operación: <span class="datos-container_numeros"> {{scoringData.resultado.gastos.total | currency}}€ </span></p>
                <div class="datos-container_desglose">
                    <p>Desglose de Gastos </p>
                        <p v-if="scoringData.resultado.gastos.itp">Impuesto ITP ({{scoringData.resultado.gastos.itp.porcentaje}}%): {{scoringData.resultado.gastos.itp.valor | currency}}€ </p>  
                        <p v-if="scoringData.resultado.gastos.ajd">Impuesto AJD ({{scoringData.resultado.gastos.ajd.porcentaje}}%): {{scoringData.resultado.gastos.ajd.valor | currency}}€ </p>  
                        <p v-if="scoringData.resultado.gastos.iva">Impuesto IVA ({{scoringData.resultado.gastos.iva.porcentaje}}%): {{scoringData.resultado.gastos.iva.valor | currency}}€ </p>
                        <p>Notaría y Registro de la Prop.: {{scoringData.resultado.gastos.notaria | currency}}€</p>   
                        <p>Honorarios de Gestión: {{scoringData.resultado.gastos.honorarios | currency}}€ </p>
                </div>
                <p style="margin: 0;">Importe de Hipoteca: <span class="datos-container_numeros"> {{scoringData.resultado.importe | currency}}€ </span></p>
                <p >Plazo {{scoringData.resultado.plazo}} años </p>
                <p>CUOTA TOTAL A PAGAR MENSUALMENTE: <span class="datos-container_numeros"> {{scoringData.resultado.cuota | currency}}€ </span></p>
            </div>

            <!-- BLOQUE FINAL CON BTN -->
            <div  style="margin:25px  0">
                <h4 style="text-align: center;">¡YA PODEMOS EMPEZAR! UN EXPERTO HIPOTECARIO DE NUESTRO EQUIPO SE PONDRÁ EN CONTACTO CONTIGO, PERO PUEDES AVANZAR POR TU CUENTA SUBIENDO DOCUMENTACIÓN A TU ZONA PRIVADA</h4>
                <div class="d-flex flex-column justify-center mt-5">
                    <button class="button-primary_element" @click="goToZP">Acceder a mi zona privada</button>
                </div>  
            </div>

            <!-- BLOQUE BANCOS -->
            <div class="bancos-container">
                <img src="../../assets/logo_color.png"  height="30px"/>
                <p class="bancos-text">
                    Esta financiación permite comprar con un producto que ningún otro Broker/Banco puede conseguir
                </p>
                <img v-if="scoringData.producto_hipotecario === 'myinvestor'" src="../../assets/logo_myinvestor.png" height="30px"/>
            </div>

    </div>
</template>


<script>
    import { mapState } from "vuex";

    export default {
        name: 'SuccessView',
        data() {
            return {
            }
        },
        mounted() {
            window.scrollTo(0, 0);
        },
        computed: {
            ...mapState([
                'scoringData'
            ]),
        },
        methods: {           
            goToZP(){
                window.location.href = `https://mi.helloteca.com/?pId=${encodeURI(this.scoringData.portal_user_token)}`;
            }
        }
    }
</script>


<style scoped>

/* TITULO */
.title-container {
    position: relative;
    display: flex; 
    justify-content: center; 
    align-items: center;
    padding: 12px; 
    border: 2px solid #5A4FA2;
    border-radius: 10px;
    font-size: 22px;
    text-align: center; 
    margin: 20px 0; 
}
.title-box {
    position: relative;
    background-color: white;
    padding: 0 10px;
    z-index: 1;
    display: inline-block;
    margin-top: -36px; 
}
@media (min-width: 550px) {
    .title-container {
        padding: 16px;
        font-size: 26px;
        margin: 50px 0;
    }
}
@media (min-width: 900px) {
    .title-container {
        font-size: 28px;
    }
}

/* CASITAS */
    .casitas-container{
        display: flex; 
        margin-top: 20px;
    }   
    .casitas-img_hello{
        width: 30%;
    }
    .casita-img_HELLO{
        width: 80px;
    }
    .casita-img_MY{
        width:100px;
    }
    .casita-img_2{
        width: 60px;
        margin-left: 12%;
        margin-top:180%;
    }
    .casita-text{
        font-size: 22px; 
        color: #12868D;
    }
    @media (min-width: 550px){
        .casitas-img_my{
            width: 35%;
            justify-content: center;
        }
        .casita-img_HELLO{
            width: 100px;
        }
        .casita-img_2{
            width: 80px;
            margin-top:40%;
            margin-left: 40%
        }
        .casita-img_MY{
        width: 200px;
        }
    }

    /* DATOS */
    .datos-container{
        font-size: 18px; 
        margin: 30px  0;
    }
    .datos-container_desglose{
        font-size: 12px; 
        color:gray; 
        /* text-align: right;  */
        line-height: 3px;
    }
    .datos-container_numeros{
        font-size: 22px; 
        font-weight: 500;
    }
    .bancos-container {
        display:flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin:20px 0;
    }
    .bancos-text{
        width: 100%;
        padding: 10px 0;    
    }

    @media (min-width: 550px) {
        .datos-container{
            display:flex; 
            flex-direction: column;  
            justify-content: flex-end; 
            align-items: flex-end; 
            font-size: 20px; 
            margin: 30px  0;
        }
        .datos-container_desglose{
            font-size: 12px; 
            color:gray; 
            line-height: 3px;
            display:flex; 
            flex-direction: column;  
            justify-content: flex-end; 
            align-items: flex-end; 
        }
        .datos-container_numeros{
            font-size: 26px; 
            font-weight: 500;
        }
        .bancos-container{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 20px 10px;
        }
        .bancos-text{
            width: 60%; 
            font-size: 14px; 
            line-height: 12px;
            text-align: center; 
            padding: 0 10px;
        }
    }

</style>