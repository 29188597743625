<template>
    <div>
        <div class="title-container">
            <p class="title-box">TU HIPOTECA 100% PARECE <span style="color:rgb(216, 61, 9)"> NO VIABLE</span></p> 
        </div>

        <div class="reasons-container">
            <h4> estos son los motivos </h4>
            <ul>
                <li v-for="(reason, key) in reasonsList" :key="key">
                    {{ reason }}                
                </li>
            </ul>        
        </div>

        <div class="actions_container" style="justify-content: center;" v-if="!iframe">
            <div class="d-flex flex-column justify-center">
                <button class="button-secundary_element" @click="goToBegin">
                    Revisar datos
                </button>
            </div>
        </div>  

    </div>
</template>


<script>
    import { mapState, mapMutations } from "vuex";

    export default {
        name: 'UnsuccessView',
        mounted() {
            window.scrollTo(0, 0);
        },
        computed: {
            ...mapState([
                'scoringData',
                'iframe'
            ]),
            reasonsList() {        
                return Object.entries(this.scoringData.resultado.motivos).map(([key, value]) => {
                    const formattedKey = key.replace(/_/g, ' ').toUpperCase()
                    return  ` ${formattedKey}: ${value}` ;
                });  
            },
        },
        methods: {
            ...mapMutations([
                'SET_ACTIVE_TAB',
            ]),
            goToBegin(){
                this.SET_ACTIVE_TAB('InitialView');
            },
        }

    }
</script>


<style scoped>
/* TITULO */
.title-container {
    position: relative;
    display: flex; 
    justify-content: center; 
    align-items: center;
    padding: 12px; 
    border: 2px solid #5A4FA2;
    border-radius: 10px;
    font-size: 22px;
    text-align: center; 
    margin: 20px 0; 
}
.title-box {
    position: relative;
    background-color: white;
    padding: 0 10px;
    z-index: 1;
    display: inline-block;
    margin-top: -36px; 
}
@media (min-width: 550px) {
    .title-container {
        padding: 16px;
        font-size: 24px;
        margin: 50px 0;
    }
}
@media (min-width: 900px) {
    .title-container {
        font-size: 26px;
    }
}
.reasons-container {
    margin: 50px 0;
}

.reasons-container ul {
    /* list-style-type: none; */
    padding-top: 10px;
}

.reasons-container li {
    margin: 10px 0;
}

</style>