import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'

Vue.config.productionTip = false

Vue.prototype.$http = axios; // Hace que Axios esté disponible globalmente como $http

Vue.use(VCurrencyField, { 
	locale: 'es',
	decimalLength: 0,
	autoDecimalMode: true,
	defaultValue: 0,
  valueAsInteger: false,
  allowNegative: false
})

Vue.filter('currency', function (value) {
  if (typeof value !== "number" || isNaN(value)) return value;
  // Formatear el número con separadores de miles
  let [integerPart, decimalPart] = value.toFixed(2).split('.');
  // Añadir el punto como separador de miles
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  // Si los decimales son 00, no los añadimos
  let formattedValue = decimalPart === '00' ? integerPart : `${integerPart},${decimalPart}`;
  return formattedValue ;
});

Vue.component('v-text-field', VTextField)

new Vue({
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

